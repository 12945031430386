import React from 'react';
import Select, { components } from 'react-select';
import HelpPoint from '../../components/Helpers/HelpPoint';
import Checkbox from '../shared/Checkbox';

const DropdownIndicator = props => (
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <span />
    </components.DropdownIndicator>
  )
);

export default class NotificationFrequenciesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailFrequencySettings: props.emailFrequencySettings,
      checkboxAllValues: props.emailFrequencies.map(frequency => frequency.id.toString()),
      selectAllValue: props.useSystemDefaults ? props.defaultAllOptions[0] : null,
      useSystemDefaults: props.useSystemDefaults,
      changeForExistingUsersValue: false,
      frequenciesChanged: false,
    };

  }

  onUnload = e => {
    let eventTargetClasses = (window.last_clicked && window.last_clicked[0] && window.last_clicked[0].classList || [])
    let formSubmitted = [...eventTargetClasses].includes('submit-form-to-check')
    let formCanceled = [...eventTargetClasses].includes('cancel-form')
    if (window.no_leave_prompt && !formCanceled) {
      window.no_leave_prompt = false;
      return;
    }
    let changeForExistingUsersUpdated = (false != this.state.changeForExistingUsersValue)
    let emailFrequencyTableUpdated = this.props.initialEmailFrequencySettings.map((el, index) => {
      if(this.state.emailFrequencySettings[index].selectedValue.value.toString() != el.selectedValue.value.toString()){
        return true
      }
      if(this.state.emailFrequencySettings[index].systemDefault.value !== el.systemDefault.value){
        return true
      }
      if(this.state.emailFrequencySettings[index].values.sort().toString() !== el.values.sort().toString()){
        return true
      }

    }).some(this.anyChanged)

    if(changeForExistingUsersUpdated || emailFrequencyTableUpdated && !formSubmitted && !formCanceled) {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  anyChanged(element) {
    return element === true
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  onCheckboxChange(setting, value, checked) {
    let { emailFrequencySettings, selectAllValue } = this.state

    emailFrequencySettings.find((freqSetting) => {
      if (freqSetting.fieldName === setting.fieldName) {
        let values = freqSetting.values

        if (checked) {
          freqSetting.values = values.filter(v => v !== value)
        } else {
          freqSetting.values.push(value)
        }
      }
    })
    this.setState({
      emailFrequencySettings: emailFrequencySettings,
      useSystemDefaults: false,
      selectAllValue: selectAllValue?.value === '0' ? null : selectAllValue,
      frequenciesChanged: true,
    })
  }

  onCheckboxAllChange(checked, value) {
    let { checkboxAllValues, emailFrequencySettings, selectAllValue } = this.state

    if (checked) {
      checkboxAllValues.push(value)
      this.setState({
        checkboxAllValues: checkboxAllValues,
        useSystemDefaults: false,
        frequenciesChanged: true,
    })
    } else {
      emailFrequencySettings.find((freqSetting) => {
        if (!freqSetting.values.includes(value)) {
          freqSetting.values.push(value)
        }
      })
      this.setState({
        checkboxAllValues: checkboxAllValues.filter(v => v !== value),
        emailFrequencySettings: emailFrequencySettings,
        useSystemDefaults: false,
        selectAllValue: selectAllValue?.value === '0' ? null : selectAllValue,
        frequenciesChanged: true,
      })
    }
  }

  onSelectChange(setting, value) {
    let { emailFrequencySettings } = this.state

    emailFrequencySettings.find((freqSetting) => {
      if (freqSetting.fieldName === setting.fieldName) {
        freqSetting.selectedValue = value
      }
    })
    this.setState({
      emailFrequencySettings: emailFrequencySettings,
      useSystemDefaults: false,
      selectAllValue: null,
      frequenciesChanged: true,
    })
  }

  onSelectAllChange(value) {
    let { emailFrequencySettings, selectAllValue, useSystemDefaults } = this.state
    selectAllValue = value

    if (value?.value === '0') {
      useSystemDefaults = true
      emailFrequencySettings.map((freqSetting) => {
        freqSetting.selectedValue = freqSetting.systemDefault
        freqSetting.values = freqSetting.defaultFrequencyValues
      })
    } else {
      useSystemDefaults = false
      emailFrequencySettings.map((freqSetting) => {
        if (freqSetting.values.includes(value?.value)) {
          freqSetting.selectedValue = value
        } else {
          freqSetting.selectedValue = null
        }
      })
    }
    this.setState({
      emailFrequencySettings: emailFrequencySettings,
      selectAllValue: selectAllValue,
      useSystemDefaults: useSystemDefaults,
      frequenciesChanged: true,
    })
  }

  onChangeExistingUsers(value) {
    window.no_leave_prompt = !value

    this.setState({
      changeForExistingUsersValue: !value,
      frequenciesChanged: true,
    })
  }

  getApplyAllRow(customStyles){
    const { emailFrequencies, defaultAllOptions, helpPoints } = this.props
    const { checkboxAllValues, selectAllValue } = this.state

    return(
      <tr>
        <td className='label-cell'>
          <label>Apply to all <HelpPoint helpPoint={helpPoints.point5} /></label>
        </td>
        { emailFrequencies.map((frequency, index) => {
            const value = frequency.id.toString()
            const checked = !checkboxAllValues.includes(value)

            return (
              <td key={`all-checkbox-${index}`}>
                <Checkbox
                  value={value}
                  checked={checked}
                  onChange={() => this.onCheckboxAllChange(checked, value)}
                />
              </td>
            )
          })
        }
        <td className="last-column custom-user-select custom-email-select">
          <Select
            options={defaultAllOptions}
            styles={customStyles}
            value={selectAllValue}
            isSearchable={false}
            placeholder={'Select Frequency'}
            onChange={(value) => this.onSelectAllChange(value)}
            classNamePrefix="UserSelector"
            components={{DropdownIndicator}}
          />
        </td>
      </tr>
    )
  }

  getFrequenciesRows(customStyles) {
    const { emailFrequencies, allOptions } = this.props
    const { emailFrequencySettings } = this.state

    return(
      emailFrequencySettings.map((setting, index) => {
        const selectValue = setting.selectedValue

        return (
          <tr key={`${setting.field_name}-${index}`}>
            <td className='label-cell'>
                <label>
                  {setting.label}
                </label>
            </td>
            { emailFrequencies.map((frequency, index) => {
                let value = frequency.id.toString()
                let checked = setting.values.includes(value)

                return(
                  <td key={`${setting.field_name}-${value}-${index}`}>
                    <Checkbox
                      value={value}
                      checked={checked}
                      name={`email_frequency_setting[${setting.fieldName}][]`}
                      onChange={() => this.onCheckboxChange(setting, value, checked)}
                    />
                  </td>
                )
              })
            }
            <td className="last-column custom-user-select custom-email-select">
              <Select
                options={allOptions.filter((option) => setting.values.includes(option.value.toString()))}
                value={setting.values.includes(selectValue?.value?.toString()) ? selectValue : null}
                styles={customStyles}
                isSearchable={false}
                placeholder={'Select Frequency'}
                name={setting.selectName}
                onChange={(value) => this.onSelectChange(setting, value)}
                classNamePrefix="UserSelector"
                components={{DropdownIndicator}}
              />
            </td>
          </tr>
        )
      })
    )
  }

  render() {
    const { notificationTypeTableCells, helpPoints } = this.props
    const { useSystemDefaults, changeForExistingUsersValue, frequenciesChanged } = this.state
    const customStyles = {
      option: (provided) => ({
        ...provided,
        fontSize: "14px",
        height: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      control: base => ({
        ...base,
        height: '24px',
        minHeight: '24px',
        maxHeight: 32,
      }),
    };

    return (
    <div>
      <label>Manage Email Frequencies <HelpPoint helpPoint={helpPoints.point1} /></label>
      <Checkbox
        label={'Change allowable frequencies for existing users'}
        name="email_frequency_setting[change_for_existing_users]"
        id="change_for_existing_users_id"
        value={changeForExistingUsersValue}
        checked={changeForExistingUsersValue}
        onChange={() => this.onChangeExistingUsers(changeForExistingUsersValue)}
        helpPoint={helpPoints.point2}
      />
      <table className="frequencies-table">
        <thead>
          <tr>
            <th className='label-cell'>Notification Type <HelpPoint helpPoint={helpPoints.point3} /></th>
              { notificationTypeTableCells.map((cellName, index) => {
                  return <th key={`${cellName}-header-${index}`}>{cellName}</th>
                })
              }
            <th className='last-cell-edit'>Default <HelpPoint helpPoint={helpPoints.point4} /></th>
          </tr>
        </thead>
        <tbody>
          { this.getApplyAllRow(customStyles) }
          { this.getFrequenciesRows(customStyles) }
        </tbody>
      </table>
      <input name='email_frequency_setting[use_system_defaults]' type="hidden" value={useSystemDefaults} />
      <input name='email_frequency_setting_has_changed' id='frequency_changed_trigger_id' type="hidden" value={frequenciesChanged} />
    </div>
    );
  }
}
